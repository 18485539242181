import React from 'react';
import {
  Box,
  Grid,
  GridItem,
  Text,
  VStack,
  Link,
  Divider,
  Heading,
  Code,
  UnorderedList,
  ListItem,
  OrderedList
} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons';
import HeaderMenus from './HeaderMenus';

function IONMCodingDocs() {
  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box textAlign="center" fontSize="xl">
      <Grid
        templateAreas={`"header header"    
                        "main results"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'350px 4fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontWeight='bold'
      >
        <HeaderMenus />

        <GridItem area="main" bg='white' p={3}>
          <br/>
          <VStack textAlign="left" align="start" spacing={3}>
            <Heading as="h3" size="m">IONM Coding API Documentation</Heading>
            <Divider />
            <Link fontSize="sm" fontWeight="normal" href="#overview" onClick={(e) => scrollToSection(e, 'overview')}>Overview</Link>
            <Link fontSize="sm" fontWeight="normal" href="#process-overview" onClick={(e) => scrollToSection(e, 'process-overview')}>Process Overview</Link>
            <Link fontSize="sm" fontWeight="normal" href="#ionm-general-workflow" onClick={(e) => scrollToSection(e, 'ionm-general-workflow')}>IONM General Workflow</Link>
            <Link fontSize="sm" fontWeight="normal" href="#ionm-workflow" onClick={(e) => scrollToSection(e, 'ionm-workflow')}>IONM Workflow (with Cadwell JSON)</Link>
            <Link fontSize="sm" fontWeight="normal" href="#list-external-cases" onClick={(e) => scrollToSection(e, 'list-external-cases')}>List External Cases</Link>
            <Link fontSize="sm" fontWeight="normal" href="#error-codes" onClick={(e) => scrollToSection(e, 'error-codes')}>Error Codes</Link>
            <Link fontSize="sm" fontWeight="normal" href="#rate-limits" onClick={(e) => scrollToSection(e, 'rate-limits')}>Rate Limits</Link>
            <Link fontSize="sm" fontWeight="normal" href="#sample-java-code" onClick={(e) => scrollToSection(e, 'sample-java-code')}>Sample Java Code</Link>
          </VStack>
        </GridItem>

        <GridItem area="results" bg='white' p={6} ml="30px" overflowY="auto" height="700px" width="1000px">
          <VStack spacing={8} textAlign="left" align="start">
            <Box id="overview">
              <Heading as="h2" size="lg" mb={2}>IONM Coding API Overview</Heading>
              <Text fontSize="md">
                The IONM (Intraoperative Neurophysiological Monitoring) Coding API provides endpoints to manage and process IONM cases. This includes creating external cases, uploading relevant files, and generating CPT codes based on the provided data. The API supports two workflows: IONM General and IONM (with Cadwell JSON).
              </Text>
            </Box>

            <Divider />

            <Box id="process-overview">
              <Heading as="h2" size="lg" mb={2}>Process Overview</Heading>
              <Text fontSize="md">
                The IONM Coding API process involves several steps, which vary slightly depending on the workflow:
              </Text>
              <Heading as="h3" size="md" mt={4}>IONM General Workflow:</Heading>
              <UnorderedList fontSize="md" fontWeight="normal">
                <ListItem><strong>Create External Case</strong>: Initialize a new case in the system with a unique external case ID and case type "IONM General".</ListItem>
                <ListItem><strong>Upload Relevant Files</strong>: Attach necessary documents to the case by uploading files.</ListItem>
                <ListItem><strong>Create and Code the Case</strong>: Transform the external case into an internal case within the system and generate CPT codes based on the provided data.</ListItem>
                <ListItem><strong>Medical Necessity Analysis</strong>: Perform medical necessity analysis on the generated CPT codes.</ListItem>
              </UnorderedList>
              <Heading as="h3" size="md" mt={4}>IONM Workflow (with Cadwell JSON):</Heading>
              <UnorderedList fontSize="md" fontWeight="normal">
                <ListItem><strong>Create External Case</strong>: Initialize a new case in the system with a unique external case ID and case type "IONM".</ListItem>
                <ListItem><strong>Upload Relevant Files</strong>: Attach necessary documents, including Cadwell JSON, to the case by uploading files.</ListItem>
                <ListItem><strong>Process IONM Case</strong>: Process the IONM case using the Cadwell JSON and generate CPT codes.</ListItem>
                <ListItem><strong>Medical Necessity Analysis</strong>: Perform medical necessity analysis on the generated CPT codes.</ListItem>
              </UnorderedList>
            </Box>

            <Divider />

            <Box id="ionm-general-workflow">
              <Heading as="h2" size="lg" mb={2}>IONM General Workflow</Heading>
              
              <Heading as="h3" size="md" mb={2}>Create External Case</Heading>
              <Text fontSize="md">
                The <Code>/create_external_case</Code> endpoint allows you to create a new external case in the system for the IONM General workflow.
              </Text>
              <Text fontSize="md">
                HTTP Method: <Code>POST</Code>
              </Text>
              <Text fontSize="md">
                URL: <Code>https://api.revedy.io/create_external_case</Code>
              </Text>
              <Text fontSize="md">
                Headers:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`Authorization: Bearer YOUR_ACCESS_TOKEN
Content-Type: application/json`}
              </Code>
              <Text fontSize="md" mt={2}>
                Request Body Parameters:
              </Text>
              <UnorderedList>
                <ListItem><Code>external_case_id</Code> (string, required): A unique identifier for the external case in your system.</ListItem>
                <ListItem><Code>case_type</Code> (string, required): Use <Code>"IONM General"</Code> for this workflow.</ListItem>
              </UnorderedList>
              <Text fontSize="md" mt={2}>
                Sample Request:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`curl --request POST \\
  --url 'https://api.revedy.io/create_external_case' \\
  --header 'Authorization: Bearer YOUR_ACCESS_TOKEN' \\
  --header 'Content-Type: application/json' \\
  --data '{
    "external_case_id": "CASE12345",
    "case_type": "IONM General"
  }'`}
              </Code>
              <Text fontSize="md" mt={2}>
                Sample Response:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`{
  "message": "External case created successfully",
  "case_id": "e8f7c3b2-6c89-4c9a-8d77-2a9ecb8e6b64"
}`}
              </Code>

              <Heading as="h3" size="md" mt={4} mb={2}>Upload File</Heading>
              <Text fontSize="md">
                The <Code>/upload_file/&lt;string:case_id&gt;</Code> endpoint allows you to upload a file associated with an external case.
              </Text>
              <Text fontSize="md">
                HTTP Method: <Code>POST</Code>
              </Text>
              <Text fontSize="md">
                URL: <Code>https://api.revedy.io/upload_file/&lt;case_id&gt;</Code>
              </Text>
              <Text fontSize="md">
                Headers:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`Authorization: Bearer YOUR_ACCESS_TOKEN
Content-Type: multipart/form-data`}
              </Code>
              <Text fontSize="md" mt={2}>
                Form Data Parameters:
              </Text>
              <UnorderedList>
                <ListItem><Code>file</Code> (file, required): The file to upload.</ListItem>
                <ListItem><Code>file_type_id</Code> (string, optional): An identifier for the type of file being uploaded.</ListItem>
              </UnorderedList>

              <Heading as="h3" size="md" mt={4} mb={2}>Create and Code Case</Heading>
              <Text fontSize="md">
                The <Code>/create_and_code_case</Code> endpoint creates an internal case from the external case and generates CPT codes based on the case data.
              </Text>
              <Text fontSize="md">
                HTTP Method: <Code>POST</Code>
              </Text>
              <Text fontSize="md">
                URL: <Code>https://api.revedy.io/create_and_code_case</Code>
              </Text>
              <Text fontSize="md">
                Headers:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`Authorization: Bearer YOUR_ACCESS_TOKEN
Content-Type: application/json`}
              </Code>
              <Text fontSize="md" mt={2}>
                Request Body Parameters:
              </Text>
              <UnorderedList>
                <ListItem><Code>case_id</Code> (string, required): The <Code>case_id</Code> obtained from the <Code>/create_external_case</Code> endpoint.</ListItem>
                <ListItem><Code>case_type</Code> (string, required): Use <Code>"IONM General"</Code> for this workflow.</ListItem>
              </UnorderedList>

              <Text fontSize="md" mt={2}>
                Sample Response:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`{
  "cpt_codes": {
    "95864": {
      "billable": true,
      "cpt_code": "95864",
      "estimated_facility_reimbursement": 236.020378,
      "estimated_non_facility_reimbursement": 236.020378,
      "facility_pe_rvu": 4.84,
      "facility_total": 6.94,
      "justification": "EMG monitoring was performed on 4 extremities (bilateral upper and lower) as well as axial muscles. This meets the criteria for code 95864 which covers EMG of 4 extremities with or without related paraspinal areas.",
      "long_description": "Needle electromyography; 4 extremities with or without related paraspinal areas",
      "mp_rvu": 0.11,
      "non_facility_pe_rvu": 4.84,
      "non_facility_total": 6.94,
      "units": 1,
      "work_rvu": 1.99
    },
    `}
              </Code>




              <Heading as="h3" size="md" mt={4} mb={2}>Medical Necessity Analysis</Heading>
              <Text fontSize="md">
                The <Code>/medical_necessity_general</Code> endpoint performs medical necessity analysis on the CPT codes associated with a case.
              </Text>
              <Text fontSize="md">
                HTTP Method: <Code>POST</Code>
              </Text>
              <Text fontSize="md">
                URL: <Code>https://api.revedy.io/medical_necessity_general</Code>
              </Text>
              <Text fontSize="md">
                Headers:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`Authorization: Bearer YOUR_ACCESS_TOKEN
Content-Type: application/json`}
              </Code>
              <Text fontSize="md" mt={2}>
                Request Body Parameters:
              </Text>
              <UnorderedList>
                <ListItem><Code>case_id</Code> (string, required): The <Code>case_id</Code> obtained from the <Code>/create_external_case</Code> endpoint.</ListItem>
                <ListItem><Code>payer</Code> (string, optional): The payer for which to perform the analysis. Default is <Code>"cms"</Code>.</ListItem>
                <ListItem><Code>codes_only</Code> (boolean, optional): If set to <Code>true</Code>, the endpoint will return only the CPT codes data without performing full analysis. Default is <Code>false</Code>.</ListItem>
              </UnorderedList>
            </Box>

            <Divider />

            <Box id="ionm-workflow">
              <Heading as="h2" size="lg" mb={2}>IONM Workflow (with Cadwell JSON)</Heading>
              
              <Heading as="h3" size="md" mb={2}>Create External Case</Heading>
              <Text fontSize="md">
                The <Code>/create_external_case</Code> endpoint allows you to create a new external case in the system for the IONM workflow with Cadwell JSON.
              </Text>
              <Text fontSize="md">
                HTTP Method: <Code>POST</Code>
              </Text>
              <Text fontSize="md">
                URL: <Code>https://api.revedy.io/create_external_case</Code>
              </Text>
              <Text fontSize="md">
                Headers:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`Authorization: Bearer YOUR_ACCESS_TOKEN
Content-Type: application/json`}
              </Code>
              <Text fontSize="md" mt={2}>
                Request Body Parameters:
              </Text>
              <UnorderedList>
                <ListItem><Code>external_case_id</Code> (string, required): A unique identifier for the external case in your system.</ListItem>
                <ListItem><Code>case_type</Code> (string, required): Use <Code>"IONM"</Code> for this workflow.</ListItem>
              </UnorderedList>
              <Text fontSize="md" mt={2}>
                Sample Request:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`curl --request POST \\
  --url 'https://api.revedy.io/create_external_case' \\
  --header 'Authorization: Bearer YOUR_ACCESS_TOKEN' \\
  --header 'Content-Type: application/json' \\
  --data '{
    "external_case_id": "CASE12345",
    "case_type": "IONM"
  }'`}
              </Code>
              <Text fontSize="md" mt={2}>
                Sample Response:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">


              {`{
  "message": "External case created successfully",
  "case_id": "e8f7c3b2-6c89-4c9a-8d77-2a9ecb8e6b64"
}`}
              </Code>

              <Heading as="h3" size="md" mt={4} mb={2}>Upload File</Heading>
<Text fontSize="md">
  The <Code>/upload_file/&lt;string:case_id&gt;</Code> endpoint allows you to upload files associated with an IONM case. Multiple files of different types need to be uploaded for proper case processing.
</Text>
<Text fontSize="md">
  HTTP Method: <Code>POST</Code>
</Text>
<Text fontSize="md">
  URL: <Code>https://api.revedy.io/upload_file/&lt;case_id&gt;</Code>
</Text>
<Text fontSize="md">
  Headers:
</Text>
<Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`Authorization: Bearer YOUR_ACCESS_TOKEN
Content-Type: multipart/form-data`}
</Code>
<Text fontSize="md" mt={2}>
  Form Data Parameters:
</Text>
<UnorderedList>
  <ListItem><Code>file</Code> (file, required): The file to upload.</ListItem>
  <ListItem><Code>file_type_id</Code> (string, required): An identifier for the type of file being uploaded.</ListItem>
</UnorderedList>
<Text fontSize="md" mt={4}>
  Required File Types:
</Text>
<UnorderedList>
  <ListItem><Code>File Type ID 1</Code>: JSON (required) - Cadwell JSON file containing case data</ListItem>
  <ListItem><Code>File Type ID 2</Code>: Chat transcript (optional) - Transcript of any relevant chat communications</ListItem>
  <ListItem><Code>File Type ID 3</Code>: Technical Report (required) - Detailed technical report of the IONM procedure</ListItem>
  <ListItem><Code>File Type ID 4</Code>: Summary Report (required) - Summary report of the IONM procedure</ListItem>
</UnorderedList>
<Text fontSize="md" mt={4}>
  Process:
</Text>
<OrderedList>
  <ListItem>Obtain the <Code>case_id</Code> from the Create External Case response.</ListItem>
  <ListItem>For each required file:
    <UnorderedList>
      <ListItem>Prepare the file for upload.</ListItem>
      <ListItem>Set the appropriate <Code>file_type_id</Code> based on the file type.</ListItem>
      <ListItem>Make a POST request to the upload endpoint with the file and file type ID.</ListItem>
    </UnorderedList>
  </ListItem>
  <ListItem>Repeat the process until all required files are uploaded.</ListItem>
</OrderedList>
<Text fontSize="md" mt={2}>
  Sample Request:
</Text>
<Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`curl --request POST \\
  --url 'https://api.revedy.io/upload_file/e8f7c3b2-6c89-4c9a-8d77-2a9ecb8e6b64' \\
  --header 'Authorization: Bearer YOUR_ACCESS_TOKEN' \\
  --header 'Content-Type: multipart/form-data' \\
  --form 'file=@/path/to/your/file.json' \\
  --form 'file_type_id=1'`}
</Code>
<Text fontSize="md" mt={2}>
  Sample Response:
</Text>
<Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`{
  "message": "File uploaded successfully",
  "file_id": "d1f9a4a8-2d6f-4c8b-9b25-1a2f4c5b6d7e"
}`}
</Code>
<Text fontSize="md" mt={4}>
  Note: Repeat this process for each required file, changing the file path and file_type_id as appropriate.
</Text>
              <Heading as="h3" size="md" mt={4} mb={2}>Process IONM Case</Heading>
              <Text fontSize="md">
                The <Code>/process_ionm_case</Code> endpoint processes the IONM case using the Cadwell JSON and generates CPT codes.
              </Text>
              <Text fontSize="md">
                HTTP Method: <Code>POST</Code>
              </Text>
              <Text fontSize="md">
                URL: <Code>https://api.revedy.io/process_ionm_case</Code>
              </Text>
              <Text fontSize="md">
                Headers:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`Authorization: Bearer YOUR_ACCESS_TOKEN
Content-Type: application/json`}
              </Code>
              <Text fontSize="md" mt={2}>
                Request Body Parameters:
              </Text>
              <UnorderedList>
                <ListItem><Code>case_id</Code> (string, required): The <Code>case_id</Code> obtained from the <Code>/create_external_case</Code> endpoint.</ListItem>
                <ListItem><Code>case_type</Code> (string, required): Use <Code>"IONM"</Code> for this workflow.</ListItem>
              </UnorderedList>
              <Text fontSize="md" mt={2}>
                Sample Request:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`curl --request POST \\
  --url 'https://api.revedy.io/process_ionm_case' \\
  --header 'Authorization: Bearer YOUR_ACCESS_TOKEN' \\
  --header 'Content-Type: application/json' \\
  --data '{
    "case_id": "e8f7c3b2-6c89-4c9a-8d77-2a9ecb8e6b64",
    "case_type": "IONM"
  }'`}
              </Code>
              <Text fontSize="md" mt={2}>
                Sample Response:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`
{
  "change_log": null,
  "cpt_codes_adjusted": null,
  "cpt_codes_full": {
    "original": {
      "95861": {
        "child_modalities": [
          "NEEDLE EMG:Lower Extremity"
        ],
        "cpt_code": "95861",
        "cpt_description": "EMG 2 Extremity, 5 muscles or more Needle electromyography, 2 extremities Needle electromyography; 2 extremities with or without related paraspinal areas. ",
        "reason": "EMG 2 Extremity, 5 muscles or more. ",
        "result": true,
        "score": 79
      },
      "95938": {
        "child_modalities": [
          "SSEP:Upper Extremity",
          "SSEP:Lower Extremity"
        ],
        "cpt_code": "95938",
        "cpt_description": "SSEP Upper & Lower Limbs Short-latency somatosensory evoked potential study of upper and lower limbs Only code for this if there is SSEP Trials data",
        "mode_data": {
          "SSEP": {
            "left_lower_limb_count": 0,
            "left_upper_limb_count": 0,
            "right_lower_limb_count": 0,
            "right_upper_limb_count": 0
          }
        },
        "reason": "SSEP study with 2 upper limb modes and 2 lower limb modes",
        "result": true,
        "score": 96
      },
      `}
              </Code>

              <Heading as="h3" size="md" mt={4} mb={2}>Medical Necessity Analysis</Heading>
              <Text fontSize="md">
                The <Code>/medical_necessity_analysis</Code> endpoint performs medical necessity analysis on the CPT codes associated with an IONM case.
              </Text>
              <Text fontSize="md">
                HTTP Method: <Code>POST</Code>
              </Text>
              <Text fontSize="md">
                URL: <Code>https://api.revedy.io/medical_necessity_analysis</Code>
              </Text>
              <Text fontSize="md">
                Headers:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`Authorization: Bearer YOUR_ACCESS_TOKEN
Content-Type: application/json`}
              </Code>
              <Text fontSize="md" mt={2}>
                Request Body Parameters:
              </Text>
              <UnorderedList>
                <ListItem><Code>case_id</Code> (string, required): The <Code>case_id</Code> obtained from the <Code>/create_external_case</Code> endpoint.</ListItem>
                <ListItem><Code>external_case_id</Code> (string, required): The external case ID associated with the case.</ListItem>
                <ListItem><Code>payer</Code> (string, optional): The payer for which to perform the analysis. Default is <Code>"cms"</Code>.</ListItem>
                <ListItem><Code>codes_only</Code> (boolean, optional): If set to <Code>true</Code>, the endpoint will return only the CPT codes data without performing full analysis. Default is <Code>false</Code>.</ListItem>
              </UnorderedList>
              <Text fontSize="md" mt={2}>
                Sample Request:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`curl --request POST \\
  --url 'https://api.revedy.io/medical_necessity_analysis' \\
  --header 'Authorization: Bearer YOUR_ACCESS_TOKEN' \\
  --header 'Content-Type: application/json' \\
  --data '{
    "case_id": "e8f7c3b2-6c89-4c9a-8d77-2a9ecb8e6b64",
    "external_case_id": "CASE12345",
    "payer": "cms",
    "codes_only": false
  }'`}
              </Code>
              <Text fontSize="md" mt={2}>
                Sample Response:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`
{
  "cpt_codes_data": {
  ... cpt codes data here ...
  },

    "results": [
      {
        "cpt_code": "95999",
        "summary_data: {
          "analysis": "The procedure performed was a \"Laminectomy L1-2\" which falls under the",
          "recommendations": "Review the specific modalities used and determine if more specific CPT codes could be used instead of the unlisted code 95999."
        },
        "analysis": {
              "explanation": "* The procedure performed was a \"Laminectomy L1-2\" which falls under the category of \"Decompressive procedures on the spinal cord or cauda equina carried out for myelopathy or claudication where function of spinal cord or spinal nerves is at risk\" (LCD35003).\n* Multiple modalities were used including SSEPs, MEPs, EMG, TOF, and EEG, which aligns with the equipment requirements outlined in both LCD34623 and LCD35003.\n* The monitoring was performed by both a local technician and a remote physician, meeting the requirements for remote monitoring (LCD34623, LCD35003).\n* Undivided attention was given to the patient by both the local technician and remote physician, which is a requirement for reimbursement (LCD35003).\n* Real-time feedback was provided to the surgeon, meeting the communication requirements (LCD34623, LCD35003).\n* The procedure was performed in a hospital setting, which is appropriate for IONM (LCD34623).\n* However, CPT code 95999 is an unlisted code and should only be used when no other specific CPT code accurately describes the service provided. It's unclear from the given information if more specific codes could have been used for the individual modalities employed.",
              "icd10_review": "* M43.16 (Spondylolisthesis, lumbar region) and M48.062 (Spinal stenosis, lumbar region with neurogenic claudication) are both relevant to the procedure and the use of IONM.\n* M48.062 should be marked as primary, as spinal stenosis with neurogenic claudication directly relates to the risk of neurological compromise during a laminectomy procedure, justifying the need for IONM.\n* M43.16 is also relevant as spondylolisthesis can increase the complexity of the surgery and the risk to neural structures.",
              "key_factors": "Positives:\n* Procedure falls under covered indications for IONM\n* Multiple modalities used, meeting equipment requirements\n* Proper staffing with local technician and remote physician\n* Undivided attention given to patient\n* Real-time feedback provided to surgeon\n* Appropriate hospital setting\n\nNegatives:\n* Use of unlisted CPT code 95999 instead of potentially more specific codes\n* Lack of detailed information about why specific modality codes were not used",
              "recommendations": "* Review the specific modalities used and determine if more specific CPT codes could be used instead of the unlisted code 95999.\n* Ensure detailed documentation is provided to justify the use of 95999 if it is determined to be the most appropriate code.\n* Clearly document the medical necessity for each modality used during the IONM.",
              "score": 50,
              "unused_sources": [
                {
                  "relevancy_explanation": "This document focuses on diagnostic nerve conduction studies (NCS) and electromyography (EMG) performed in a clinical setting for the diagnosis of neuromuscular disorders. It outlines the requirements for performing and billing these procedures, including provider qualifications, appropriate indications, and limitations.  It does *not* discuss intraoperative neuromonitoring (IONM), which uses similar techniques but in a surgical setting to monitor nerve function and prevent damage during procedures.  While some of the underlying principles of NCS and EMG apply to both diagnostic and intraoperative contexts, the document's focus on diagnostic testing makes it largely irrelevant to CPT code 95999 for IONM. The document specifically states that portable hand-held devices incapable of real-time waveform display and analysis will not be paid separately, which is often the type of equipment used in IONM.",
                  "source_id": "LCD34594",
                  "source_type": "lcd"
                }
              ],
              "used_sources": [
                {
                  "relevancy_explanation": "This document provides extensive information about intraoperative neurophysiological testing, specifically outlining the types of surgeries where such monitoring is considered medically necessary.  While it doesn't explicitly mention CPT code 95999, the detailed descriptions of procedures and requirements for monitoring strongly suggest that 95999 would be the appropriate code for situations where the specific monitoring modalities (e.g., SSEP, MEP, EEG) don't have dedicated CPT codes.  The document emphasizes the complexity and medical necessity of these services, aligning with the use of an unlisted code for less common or complex neurophysiological monitoring procedures. The focus on medical necessity criteria, the requirement for a separate physician to perform the monitoring, and the technical specifications further reinforce the relevance of this information to the use of 95999 for intraoperative neuromonitoring in specific scenarios.",
                  "source_id": "LCD34623",
                  "source_type": "lcd"
                },
                {
                  "relevancy_explanation": "This document specifically addresses intraoperative neurophysiological testing and lists numerous covered indications for this type of monitoring.  While it doesn't mention CPT code 95999 specifically, it does extensively detail the requirements and limitations for coverage of intraoperative neurophysiological monitoring, which is the context in which 95999 might be used when a more specific code doesn't exist for the particular monitoring technique being employed.  The detailed coverage parameters provided here are highly relevant for understanding when intraoperative neuromonitoring is considered medically necessary and therefore potentially billable, even if the specific code used is an unlisted code like 95999.  The information about required personnel, equipment, and eligible place of service is particularly relevant for someone considering billing 95999.",
                  "source_id": "LCD35003",
                  "source_type": "lcd"
                }
              ]
          }
        },  
  ]
  `}
              </Code>
            </Box>

            <Divider />

            <Box id="list-external-cases">
              <Heading as="h3" size="md" mb={2}>List External Cases</Heading>
              <Text fontSize="md">
                The <Code>/list_external_cases</Code> endpoint retrieves a list of external cases associated with the authenticated user.
              </Text>
              {/* Add more details about this endpoint if needed */}
            </Box>

            <Divider />

            <Box id="error-codes">
              <Heading as="h3" size="md" mb={2}>Error Codes</Heading>
              <Text fontSize="md">
                The API uses standard HTTP status codes to indicate success or failure of API requests. Common status codes include:
              </Text>
              <UnorderedList>
                <ListItem><Code>200 OK</Code>: The request was successful.</ListItem>
                <ListItem><Code>201 Created</Code>: The resource was successfully created.</ListItem>
                <ListItem><Code>400 Bad Request</Code>: The request was invalid or cannot be processed.</ListItem>
                <ListItem><Code>401 Unauthorized</Code>: Authentication failed or was not provided.</ListItem>
                <ListItem><Code>403 Forbidden</Code>: The authenticated user does not have access to the requested resource.</ListItem>
                <ListItem><Code>404 Not Found</Code>: The requested resource does not exist.</ListItem>
                <ListItem><Code>500 Internal Server Error</Code>: An unexpected error occurred on the server.</ListItem>
              </UnorderedList>
              <Text fontSize="md" mt={2}>
                Errors are returned in JSON format with an <Code>error</Code> message. For example:
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
{`{
  "error": "Case not found or access denied"
}`}
              </Code>
            </Box>

            <Divider />

            <Box id="rate-limits">
              <Heading as="h3" size="md" mb={2}>Rate Limits</Heading>
              <Text fontSize="md">
                To ensure fair use of the API, rate limits may be applied. If you exceed the allowed number of requests, you may receive a <Code>429 Too Many Requests</Code> response. Please contact support if you require higher rate limits.
              </Text>
            </Box>

            <Divider />

            <Box id="sample-java-code">
              <Heading as="h3" size="md" mb={2}>Sample Java Code</Heading>
              <Text fontSize="md">
                Below is a sample Java code snippet demonstrating how to interact with the IONM Coding API for both IONM General and IONM workflows.
              </Text>
              <Code p={2} bg="gray.100" display="block" whiteSpace="pre">
              {`
            import java.io.File;
            import java.net.http.HttpClient;
            import java.net.http.HttpRequest;
            import java.net.http.HttpResponse;
            import java.net.URI;
            import java.nio.file.Path;
            
            public class IONMApiExample {
                private static final String API_BASE_URL = "https://api.revedy.com";
                private static final String ACCESS_TOKEN = "YOUR_ACCESS_TOKEN";
            
                public static void main(String[] args) throws Exception {
                    // IONM General Workflow
                    String generalCaseId = createExternalCase("CASE12345", "IONM General");
                    uploadFile(generalCaseId, "path/to/file.pdf", "3");
                    createAndCodeCase(generalCaseId, "IONM General");
                    performMedicalNecessityAnalysis(generalCaseId, "cms", false);
            
                    // IONM Workflow (with Cadwell JSON)
                    String ionmCaseId = createExternalCase("CASE67890", "IONM");
                    uploadFile(ionmCaseId, "path/to/cadwell.json", "1");
                    uploadFile(ionmCaseId, "path/to/technical_report.pdf", "3");
                    uploadFile(ionmCaseId, "path/to/summary_report.pdf", "4");
                    processIonmCase(ionmCaseId);
                    performMedicalNecessityAnalysis(ionmCaseId, "cms", false);
                }
            
                private static String createExternalCase(String externalCaseId, String caseType) throws Exception {
                    String requestBody = String.format("{\"external_case_id\":\"%s\",\"case_type\":\"%s\"}", externalCaseId, caseType);
                    HttpResponse<String> response = sendPostRequest("/create_external_case", requestBody);
                    // Parse and return case_id from response
                    return "parsed_case_id";
                }
            
                private static void uploadFile(String caseId, String filePath, String fileTypeId) throws Exception {
                    HttpClient client = HttpClient.newBuilder().build();
                    HttpRequest request = HttpRequest.newBuilder()
                        .uri(URI.create(API_BASE_URL + "/upload_file/" + caseId))
                        .header("Authorization", "Bearer " + ACCESS_TOKEN)
                        .POST(HttpRequest.BodyPublishers.ofFile(Path.of(filePath)))
                        .build();
                    HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());
                    System.out.println("File upload response: " + response.body());
                }
            
                private static void createAndCodeCase(String caseId, String caseType) throws Exception {
                    String requestBody = String.format("{\"case_id\":\"%s\",\"case_type\":\"%s\"}", caseId, caseType);
                    HttpResponse<String> response = sendPostRequest("/create_and_code_case", requestBody);
                    System.out.println("Create and code case response: " + response.body());
                }
            
                private static void processIonmCase(String caseId) throws Exception {
                    String requestBody = String.format("{\"case_id\":\"%s\",\"case_type\":\"IONM\"}", caseId);
                    HttpResponse<String> response = sendPostRequest("/process_ionm_case", requestBody);
                    System.out.println("Process IONM case response: " + response.body());
                }
            
                private static void performMedicalNecessityAnalysis(String caseId, String payer, boolean codesOnly) throws Exception {
                    String requestBody = String.format("{\"case_id\":\"%s\",\"payer\":\"%s\",\"codes_only\":%b}", caseId, payer, codesOnly);
                    HttpResponse<String> response = sendPostRequest("/medical_necessity_analysis", requestBody);
                    System.out.println("Medical necessity analysis response: " + response.body());
                }
            
                private static HttpResponse<String> sendPostRequest(String endpoint, String requestBody) throws Exception {
                    HttpClient client = HttpClient.newBuilder().build();
                    HttpRequest request = HttpRequest.newBuilder()
                        .uri(URI.create(API_BASE_URL + endpoint))
                        .header("Authorization", "Bearer " + ACCESS_TOKEN)
                        .header("Content-Type", "application/json")
                        .POST(HttpRequest.BodyPublishers.ofString(requestBody))
                        .build();
                    return client.send(request, HttpResponse.BodyHandlers.ofString());
                }
            }
            
            
            `}
              </Code>
            </Box>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default IONMCodingDocs;