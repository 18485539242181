//CaseServiceTable.js
import React, { useState, useEffect, useMemo, useContext } from 'react';

import { useTable, useSortBy } from 'react-table';

import { Box, Grid, GridItem, Button } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";

import HeaderMenus from './HeaderMenus';
import { handleCaseList } from './caseService';
 
 import { callApi } from '../callApi';
  import { useNavigate } from 'react-router-dom';
   import { AppContext } from '../components/AppContext';

function CaseServiceTable({ handleCaseButtonClick }) { 
  const [cases, setCases] = useState([]);
  const [loadingCases, setLoadingCases] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 100;
  const navigate = useNavigate();
  const { accessToken, loading, appState, setAppState } = useContext(AppContext);
  const [sortConfig, setSortConfig] = useState(null);

  const totalPages = Math.ceil(cases.length / rowsPerPage);

  const sortedCases = useMemo(() => {
    if (sortConfig === null) {
      return cases;
    }
  
    return [...cases].sort((a, b) => {

      // Handle sorting for other columns
      const aValue = a[sortConfig.key] || '';
      const bValue = b[sortConfig.key] || '';

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    
    });
  }, [cases, sortConfig]);

  const currentData = useMemo(() => { const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return sortedCases.slice(startIndex, endIndex);
  }, [sortedCases, currentPage, rowsPerPage]);


  const preprocessCases = (cases) => {
    return cases.map(caseItem => {
      const processedCase = { ...caseItem };
      
      const processingDataMap = {
        'pre_success': ['revedy_in', 'Success'],
        'pre_exceptions': ['revedy_in', 'Exceptions'],
        'post_revedy': ['revedy_out', 'Revedy'],
        'post_processed': ['revedy_out', 'Processed'],
        'post_exception': ['revedy_out', 'Exception'],
        'post_tech_incomplete': ['revedy_out', 'Tech_Incomplete'],
        'post_no_claim': ['revedy_out', 'No_claim'],
        'post_new_temp': ['revedy_out', 'New_temp'],
        'post_exception_new': ['revedy_out', 'Exception_New'],
        'post_revedy2': ['revedy_out', 'Revedy2'],
        'post_temp': ['revedy_out', 'temp'],
        'post_processed_new': ['revedy_out', 'Processed_new'],
        'pre_reports_legacy': ['revedy_in', 'ReportsLegacy'],
      };
  
      Object.entries(processingDataMap).forEach(([key, [server, directory]]) => {
        const item = caseItem.processing_data?.find(v => v.server === server && v.directory === directory);
        processedCase[key] = item?.timestamp || "n/a";
      });
  
      return processedCase;
    });
  };







  const fetchCases = async (searchTerm) => {
    const urlParams = new URLSearchParams(window.location.search);
    const codingIssues = urlParams.get('i');
    
    const perIssue = 1;
    const caseId = "";

    try {
      const fetchedCases = await handleCaseList(callApi, accessToken, "", urlParams.get('i'), searchTerm, 1);
      const processedCases = preprocessCases(fetchedCases);
      setCases(processedCases);
      //setCases(fetchedCases);      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoadingCases(false);
  };



  const handleSort = (column) => {
    if (sortConfig && sortConfig.key === column.id) {
      // If the same column is clicked, toggle the sort direction
      setSortConfig({
        key: column.id,
        direction: sortConfig.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      // If a different column is clicked, set the new column and default sort direction
      setSortConfig({
        key: column.id,
        direction: 'asc',
      });
    }
  };

  useEffect(() => { fetchCases("");
  }, [accessToken]);

  const data = React.useMemo(() => cases, [cases]);

  function formatDateWithTimeZone(value) { 
    if (value === null || value === undefined || value === '' || value === 'None') 
      { return 'N/A';
    } 
    try {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const date = new Date(value);
      const options = { timeZone, month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' };
      return new Intl.DateTimeFormat('en-US', options).format(date);
    }
    catch (error) {
      console.error('Error formatting date:', error);
      console.error('Value:', value);
      return 'N/A';
    }

    }

  const columns = React.useMemo( () => [ 
{ 
    Header: 'Patient ID', 
    accessor: 'external_patient_id',
    Cell: ({ value }) => value || 'N/A', 
  },
  { 
    Header: 'For Review',
    accessor: 'coding_issues',
  },
  {
    Header: 'Coverage',
    Cell: ({ row }) => (
      <>
        <a
          href={`/medical-necessity-analysis?case_id=${row.original.case_id}&paye=cms`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Medicare
        </a>
        <br/>
        <a
          href={`/medical-necessity-analysis?case_id=${row.original.case_id}&payer=aetna`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Aetna
        </a>
      </>
    ),
  },
  { 
    Header: 'Procedure Date',
    accessor: 'procedure_date',
    Cell: ({ value }) => formatDateWithTimeZone(value),
  },
  { 
    Header: 'Initial Processing',
    accessor: 'create_date',
    Cell: ({ value }) => formatDateWithTimeZone(value),
  },
  { 
    Header: 'Last Updated',
    accessor: 'updated_at',
    Cell: ({ value }) => formatDateWithTimeZone(value),
  },
  { 
    Header: 'Last Name',
    accessor: 'patient_last_name',
  },
  { 
    Header: 'Case Name',
    accessor: 'case_name',
  },
  { 
    Header: 'ICD-10 Codes',
    accessor: 'icd10_codes',
  },
  { 
    Header: 'Secondary Patient ID',
    accessor: 'external_patient_id_secondary',
  },
  {
    Header: 'Cadwell Case ID',
    accessor: 'case_id',
    Cell: ({ value }) => <>{value}</>,
  },      


      {
        Header: 'Pre Success',
        accessor: 'pre_success',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },
      {
        Header: 'Pre Exceptions',
        accessor: 'pre_exceptions',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },
      {
        Header: 'Post Revedy',
        accessor: 'post_revedy',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },
      {
        Header: 'Post Processed',
        accessor: 'post_processed',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },
      {
        Header: 'Post Exception',
        accessor: 'post_exception',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },
      {
        Header: 'Post Tech_Incomplete',
        accessor: 'post_tech_incomplete',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },
      {
        Header: 'Post No_claim',
        accessor: 'post_no_claim',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },
      {
        Header: 'Post New_temp',
        accessor: 'post_new_temp',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },
      {
        Header: 'Post Exception_New',
        accessor: 'post_exception_new',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },
      {
        Header: 'Post Revedy2',
        accessor: 'post_revedy2',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },
      {
        Header: 'Post temp',
        accessor: 'post_temp',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },
      {
        Header: 'Post Processed_new',
        accessor: 'post_processed_new',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },
      {
        Header: 'Pre ReportsLegacy',
        accessor: 'pre_reports_legacy',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },




      ], [] );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: currentData },
    useSortBy
  );

  

function convertToCSV(data) {
  const headers = columns.map(col => col.Header).join(',');
  const rows = data.map(row =>
    columns.map(col => {
      const cellValue = row[col.accessor];
      return cellValue !== undefined && cellValue !== null
        //? `"${col.Cell ? col.Cell({ value: cellValue }).props.children : cellValue}"`
        ? `"${col.Cell ? (col.Cell({ value: cellValue }).props?.children || cellValue) : cellValue}"`

        : '';
    }).join(',')
  );
  return [headers, ...rows].join('\n');
}



function downloadCSV(csvString, filename) {
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}





const handleExportToCSV = async () => {
  setIsLoading(true);
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const codingIssues = urlParams.get('i');
    
    const csvBlob = await handleCaseList(callApi, accessToken, "", codingIssues, "", 1, "csv");
    
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'RevedyProcessReport.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error exporting CSV:', error);
  } finally {
    setIsLoading(false);
  }
};





  return ( 
<Box textAlign="center" fontSize="xl"> 
<Grid templateAreas={`"header header" "main main" "form form" "footer footer"`} 
gridTemplateRows={'60px auto 1fr 60px'} gridTemplateColumns={'1fr 1fr'} h='100%' gap='2' color='blackAlpha.800' fontSize='14px' fontWeight='medium' > <HeaderMenus />

    <GridItem bg='gray.50' padding={4} area={'main'} verticalAlign={"bottom"}>

    {loadingCases && <>Loading...</>}
    {cases && (
      <>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="1em">
                <Box display="flex" alignItems="center">
                  <Input
                    placeholder="Search..."
                    width="140px"
                    marginRight="1em"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        fetchCases(e.target.value);
                      }
                    }}
                  />
                  <Button onClick={(e) => fetchCases(e.target.previousSibling.value)}>Search</Button>
                  <Button onClick={(e) => {
                    fetchCases('');
                    e.target.previousSibling.previousSibling.value = '';
                  }} marginLeft="1em">
                    Clear
                  </Button>
                </Box>

                <Box display="flex" justifyContent="center" marginTop="1em">
      <Button
        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        disabled={currentPage === 1}
        marginRight="1em"
      >
        Previous
      </Button>
      <span>
        Page {currentPage} of {totalPages}
      </span>
      <Button
        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        disabled={currentPage === totalPages}
        marginLeft="1em"
      >
        Next
      </Button>
    </Box>

    


                <Box>
                  <a href="/ionmtable" rel="noopener noreferrer" style={{ marginRight: '10px', marginLeft: '10px' }}>All Results</a>
                  <a href="/ionmtable?i=1" rel="noopener noreferrer" style={{ marginRight: '10px' }}>For Review</a>
                  <button onClick={handleExportToCSV}>Export to CSV</button>
                </Box>
              </Box>
    
        <Box overflowX="auto" display="flex" justifyContent="center"> {/* Center the table */}
          <table {...getTableProps()} style={{ width: '100%', borderSpacing: '0' }}> {/* Make the table a little narrower */}
          <thead>
  {headerGroups.map(headerGroup => (
    <tr {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map(column => (
        <th
          {...column.getHeaderProps()}
          onClick={() => handleSort(column)}
          style={{
            borderBottom: 'solid 3px red',
            background: 'aliceblue',
            color: 'black',
            fontWeight: 'bold',
            width: '20%',
            cursor: 'pointer',
          }}
        >
          {column.render('Header')}
          <span>
            {sortConfig && sortConfig.key === column.id
              ? sortConfig.direction === 'asc'
                ? ' 🔼'
                : ' 🔽'
              : ''}
          </span>
        </th>
      ))}
    </tr>
  ))}
</thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                if (!row.original.external_patient_id || row.original.external_patient_id === 'None') {
                  return null; // Skip rendering this row
                }
                
                if (!row) return null;

                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                  >
                  {row.cells.map((cell,index) => {
                      return (
                        <td
                          {
                            ...cell.getCellProps()
                          }
                          style={{
                            padding: '10px',
                            border: 'solid 1px gray',
                            color: index === 0 ? 'blue' : 'black',
                            background: 'white',
                            cursor: index === 0 ? 'pointer' : 'default',
                          }}
                          onClick={() => {
                            if (index === 0) {
                              const url = `/ionmform?caseid=${row.original.case_id}`;
                              window.open(url, "_blank");
                            }
                          }}
                        >
                          {
                          
                          cell.render('Cell')
                          }
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>


    </>
)}

    </GridItem>
  </Grid>
</Box>
)

}

export default CaseServiceTable;