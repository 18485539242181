// caseService.js






export const handleCaseList = async (callApi, accessToken, caseId="", codingIssues=0, searchTerm = "", perIssue=false, outputFormat="json") => {
  try {
    let response = await callApi('/getcases', accessToken, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        issue: codingIssues,
        search_term: searchTerm,
        per_issue: perIssue,
        case_id: caseId,
        output_format: outputFormat
      })
    });

    if (outputFormat === "csv") {
      // For CSV, return the blob directly
      return await response.blob();
    } else {
      // For JSON, parse the response and return the cases
      let results = await response.json();
      return results.cases || []; // return an empty array if results.cases is undefined
    }
  } catch (error) {
    console.error(error);
    return outputFormat === "csv" ? new Blob() : []; // return appropriate empty result based on format
  }
};


