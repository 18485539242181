import React, { useState, useEffect, useContext } from 'react';
import { callApi } from '../callApi'; 
import { AppContext } from '../components/AppContext';
import ReactMarkdown from 'react-markdown';
import RelevancyResults from './IONMMedicalNecessity';


import { ChakraProvider, Box, Grid, GridItem, Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel,
    Spinner,
    Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Divider, } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react"
import { useTable, useSortBy } from 'react-table';

import { extendTheme } from '@chakra-ui/react';
import AccountMenu from '../account';
import CaseServiceList from './CaseServiceList';
import HeaderMenus from './HeaderMenus';
import { json, useLocation } from 'react-router-dom';
import Chat from './Chat';
import { handleCaseList } from './caseService'; 
import ChatToggle from './ChatToggle';


const colors = {
    brand: {
      900: '#8ECAE6', //light blue
      800: '#219EBC', //blue
      700: '#023047', //dark blue
      600: '#FFB703', //yellow
      500: '#FB8500' //orange
    },
  };
  
  // Extend the theme
  const theme = extendTheme({ colors });

  
function CodeIOM () {
    const [patientName, setPatientName] = useState('Jane Smith');
    const [patientId, setPatientId] = useState('123456789');
    const [caseId, setCaseId] = useState('');
    const [jsonData, setJsonData] = useState('');
    const [caseFiles, setCaseFiles] = useState([]);
    const [otherData, setOtherData] = useState('');
    const [cpt1, setCpt1] = useState('');
    const [cpt1desc, setCpt1desc] = useState('');
    const [pdfUrl, setPdfUrl] = useState(null);
    const [resultsReceived, setResultsReceived] = useState(false);
    const [resultsmReceived, setResultsmReceived] = useState(false);
    const [cptData, setCptData] = useState('');
    const [cptmData, setCptmData] = useState('');
    const { accessToken, loading, appState, setAppState } = useContext(AppContext);
    const [results, setResults] = useState(undefined);
    const [resultsValue, setResultsValue] = useState(undefined);
    const [cases, setCases] = useState([]);
    const [currentCaseIndex, setCurrentCaseIndex] = useState(0);
    const [caseIds, setCaseIds] = useState([]);
    const [codingInProgress, setCodingInProgress] = useState(false);
    const location = useLocation();
    const [autoRun, setAutoRun] = useState(false);
    const [chatSummaries, setChatSummaries] = useState(null);
    const [statusMapping, setStatusMapping] = useState({});
    const [selectedPayer, setSelectedPayer] = useState('cms');

    const [relevancyResults, setRelevancyResults] = useState({});
    const [relevancyErrors, setRelevancyErrors] = useState({});
    const [gettingRelevancyResults, setGettingRelevancyResults] = useState({});

    const allModalities = [
      ['OTHER MODALITIES', 'BCR'],
      ['EEG_ECOG', 'EEG'],
      ['EEG_ECOG', 'ECOG'],
      ['MAPPING', 'Subcortical Motor Mapping'],
      ['MAPPING', 'Cortical Motor Mapping'],
      ['MAPPING', 'Language Mapping'],
      ['NEEDLE EMG', 'Upper Extremity'],
      ['NEEDLE EMG', 'Cranial Nerve'],
      ['NEEDLE EMG', 'Axial Muscles'],
      ['NEEDLE EMG', 'Lower Extremity'],
      ['NEEDLE EMG', 'Sacral Muscles'],
      ['VOCALIS EMG', 'Laryngeal EMG'],
      ['VOCALIS EMG', 'LAR'],
      ['BAER', 'BAERs'],
      ['MAPPING', 'Phase Reversal'],
      ['MAPPING', 'Dorsal Column Mapping'],
      ['OTHER MODALITIES', 'VEP'],
      ['MEP', 'Upper Extremity'],
      ['MEP', 'Lower Extremity'],
      ['MEP', 'TaMEPs'],
      ['OTHER MODALITIES', 'H-REFLEX'],
      ['MEP', 'D-Wave'],
      ['NEEDLE EMG', 'Pedicle Screw Testing'],
      ['NEEDLE EMG', 'Direct Nerve Stimulation'],
      ['SSEP', 'Upper Extremity'],
      ['SSEP', 'Lower Extremity'],
      ['SSEP', 'Peripheral Nerves'],
      ['NEEDLE EMG', 'Nerve Proximity'],
      ['MAPPING', 'Mapping Duration'],
      ['TO4', '(T04)'],
    ]

    const [testsRun, setTestsRun] = useState({
        SSEP: { isRun: false, data: "" },
        MEP: { isRun: false, data: "" },
        EMG: { isRun: false, data: "" },
        To4: { isRun: false, data: "" },
        BAER: { isRun: false, data: "" },
        NerveConduction: { isRun: false, data: "" },
        EEG: { isRun: false, data: "" },
        Other: { isRun: false, data: "" },
      });


    
    const openFileDialog = () => {
        document.getElementById('fileInput').click();
    };
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // Now you can handle the file. For example, you can read its content or upload it to a server.
    };

    useEffect(() => {
      const fetchCases = async () => {
        const params = new URLSearchParams(location.search);
        let caseIdFromSystem = params.get('caseid');

        if (!caseIdFromSystem) {            
          if (appState.caseId) {
            caseIdFromSystem = appState.caseId;
          }
        }
        if (!caseIdFromSystem) {
          return;
        }
        let cases = await handleCaseList(callApi, accessToken, caseIdFromSystem);
        
        setCases(cases);
        if (cases.length > 0) {
          const ids = cases.map(caseItem => caseItem.case_id);
          setCaseIds(ids);
    
          const params = new URLSearchParams(location.search);
          const caseIdFromUrl = params.get('caseid');
          let newIndex = 0;

          newIndex = ids.indexOf(caseIdFromSystem);
    
          setCurrentCaseIndex(newIndex);
          setAppState(prevState => ({
            ...prevState,
            caseId: ids[newIndex]
          }));

        }
      };
      fetchCases();
    }, [location]);

    React.useEffect(() => {
      if (appState.caseId) {
        setTestsRun(null);
        setCpt1desc(null);

        handleCaseButtonClick(appState.caseId);

        console.log("caseId: ", appState.caseId);

        const newIndex = caseIds.indexOf(appState.caseId);

        if (newIndex !== -1) {
          setCurrentCaseIndex(newIndex);
        }
      }
    }, [appState.caseId]);


    function getTestsRun(data) {
        const tests = {
          SSEP: { isRun: false, data: "" },
          MEP: { isRun: false, data: "" },
          EMG: { isRun: false, data: "" },
          To4: { isRun: false, data: "" },
          BAER: { isRun: false, data: "" },
          NerveConduction: { isRun: false, data: "" },
          EEG: { isRun: false, data: "" },
          Other: { isRun: false, data: "" },
        };
      
        const ssepCodes = new Set(["95925", "95926", "95938"]);
        const mepCodes = new Set(["95928", "95929", "95939"]);
        const emgCodes = new Set(["51785","95860", "95861", "95864", "95865", "95867", "95868", "95870", "95885", "95886", "95887", "EMG from chat"]);
        const to4Codes = new Set(["95999"]);
        const nerveConductionCodes = new Set(["95907", "95908", "95909", "95910", "95911", "95912", "95913"]);
        const baerCodes = new Set(["92653"]);
        const eegCodes = new Set([ "95822", "95955"]);
      
        console.log("data : ", data);

        for (const item of data) {
            const code = item.cpt_code;
            if (ssepCodes.has(code)) {
              tests.SSEP.isRun = true;
              tests.SSEP.data += item.reason + ' ';
            } else if (mepCodes.has(code)) {
              tests.MEP.isRun = true;
              tests.MEP.data += item.reason + ' ';
            } else if (emgCodes.has(code)) {
              tests.EMG.isRun = true;
              tests.EMG.data += item.reason + ' ';
            } else if (to4Codes.has(code)) {
              tests.To4.isRun = true;
              tests.To4.data += item.reason + ' ';
            } else if (baerCodes.has(code)) {
              tests.BAER.isRun = true;
              tests.BAER.data += item.reason + ' ';
            } else if (nerveConductionCodes.has(code)) {
              tests.NerveConduction.isRun = true;
              tests.NerveConduction.data += item.reason + ' ';
            } else if (eegCodes.has(code)) {
              tests.EEG.isRun = true;
              tests.EEG.data += item.reason + ' ';
            } else {
              tests.Other.isRun = true;
              tests.Other.data += item.reason + '\n';
            }
          }
              
        return tests;
      }

      const TestCard = ({ title, data, isTestRun, spanTwoColumns }) => {
        const lines = data.split('\n').map((line, index, array) => (
          <React.Fragment key={index}>
            <Text fontSize="sm">{line}</Text>
            {index !== array.length - 1 && <br />}
          </React.Fragment>
        ));
      
        return (
          <Box
            textAlign={'left'}
            bg={isTestRun ? "green.500" : "gray.200"}
            color={isTestRun ? "white" : "black"}
            p={4}
            borderRadius="md"
            boxShadow="sm"
            gridColumn={spanTwoColumns ? "span 2" : null}
          >
            <Text fontSize="lg" mb={2}>
              {title}
            </Text>
            {lines}
          </Box>
        );
      };

    const handleMNClick = async (case_id, payer_name) => {
        console.log("getting case results for case_id: " + case_id);
        setRelevancyResults(prev => ({ ...prev, [payer_name]: null }));
        setRelevancyErrors(prev => ({ ...prev, [payer_name]: null }));
        setGettingRelevancyResults(prev => ({ ...prev, [payer_name]: true }));

        try {
            let response = await callApi('/ionm_process_case', accessToken, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({case_id: case_id,
                                      payer_name: payer_name,
                })
                });
            let results = await response.json();
          setGettingRelevancyResults(prev => ({ ...prev, [payer_name]: false }));

          if (results.error) {
              setRelevancyErrors(prev => ({ ...prev, [payer_name]: results.error }));
          } else {
              setRelevancyResults(prev => ({ ...prev, [payer_name]: results }));
          }
          console.log("handleMNclick: got results with length: ", results.length);
        } catch (error) {
          console.error(error);
        }
    };

    const handleFileClick = async (externalPatientId, fileType) => {
      try {
        const response = await callApi('/getfile', accessToken, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            external_patient_id: externalPatientId, 
            file_type: fileType 
          }),
        });
    
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const newTab = window.open(url, '_blank');  // Open the PDF in a new tab
    
          // Optional: Check if the new tab was blocked by the browser
          if (newTab) {
            newTab.focus();  // Focus on the new tab if it was successfully opened
          } else {
            alert('Please allow pop-ups for this website.');  // Inform the user if the pop-up was blocked
          }
    
          // Consider delaying the revocation of the Blob URL
          setTimeout(() => {
            window.URL.revokeObjectURL(url);  // Revoke the URL after the new tab has loaded the content
          }, 100);  // Delay can be adjusted based on needs
        } else {
          console.error('Error downloading file:', response.statusText);
        }
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };

    const handleBackfillClick = async (case_id) => {
        console.log("getting case results for case_id: " + case_id);
        try {
            let response = await callApi('/codecases', accessToken, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                });
            let results = await response.json();
    
            console.log("handlebackfillclick: got results:", results);
          }
          catch (error) {
            console.error(error);
          }
    };

    const handleCaseButtonClick = async ( case_id) => {
        console.log("getting case results for case_id: " + case_id);
        try {
            let response = await callApi('/getcase', accessToken, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({case_id: case_id,})
                });
            let results = await response.json();
    
            console.log("handlecasebuttonclick: got results with length: ", results.length);
            // Check if the response contains an error code
            if (results.error === 'Case not found') {
              // No case was found, so show an error message or handle the error in another way
              console.error('No case found with id ' + case_id);
              return;
            }
            //update jsonData and otherData
            if (results.system_summary) {
                setJsonData(JSON.stringify(results.system_summary, null, 2));
            }
            if (results.case_files) {
              setCaseFiles(results.case_files);
            }
            setOtherData(JSON.stringify(results.chat_summary, null, 2));
            setCaseId(case_id);
            setCpt1desc(null);
            setResults(results);
            console.log("handlecasebuttonclick: set results to: ", results);
            setResultsValue(null)

            let dataType = "no_messages";
            setResultsReceived(false);
            setResultsmReceived(false);
            setPdfUrl(null);
            if (dataType === "no_messages") {
                setCptData(null);
                setCptmData(null);
    
                setCpt1(null);
    
                setCpt1desc(null);
            }
            else {
            }
    
            
            let cptSetters = [];
            let descSetters = [];
            setCodingInProgress(true);
    
            const code_response = await callApi('/ionm_code_from_json', accessToken, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data_type: dataType,
                    case_id: case_id,
                }),
            });
            const data = await code_response.json();
            if (!data.original) {
                return;
            }
            const dataArray = Object.values(data.original);
            const Statuses = {};
            dataArray && dataArray.forEach(data => {
              data.child_modalities && data.child_modalities.forEach(modality => {
                Statuses[modality] = "yes";
              });
            });
            setStatusMapping(Statuses);
    
            const tests = getTestsRun(dataArray);
            setTestsRun(tests);
            const list = Object.entries(data).filter(([key]) => key !== 'request_id'); // Exclude the request_id from the list
    
            const formattedCptData = [];
    
            for (const layer of ['tech', 'pro', 'original']) {
              formattedCptData.push(<Text fontSize="xl" color="3e81c3" textAlign="left" marginLeft={2}>{layer.charAt(0).toUpperCase() + layer.slice(1)}</Text>);
          
              // Convert the object entries to an array and sort based on the score
              const sortedEntries = Object.entries(data[layer]).sort((a, b) => b[1].score - a[1].score);
          
              for (const [cpt, cptData] of sortedEntries) {
                  const desc = cptData.cpt_description;
                  let reason = cptData.reason;
                  // if type is in cptData, then add "(preliminary) to the cpt code"
                  if (cptData.type === 'preliminary') {
                      cptData.cpt_code += ' (needs review, please see Reason section)';
                  }
                  //display reason on web page with line breaks using jsx
                  const reasonLines = reason.split('\n');
                  
                  const jsxReasonLines = reasonLines.map((line, index) => (
                      <Text
                          key={index}
                          align="left"
                          ml={4}
                          fontSize="md"
                          fontWeight="normal"
                          backgroundColor={cptData.type === 'preliminary' ? 'lightcoral' : 'transparent'}
                      >
                          {line}
                      </Text>
                  ));
                  reason = jsxReasonLines;
          
                  if (cptData.result == false) {
                      continue;
                  }
          
                  // Format the CPT data as JSX
                  // make a unique key with cpt-code
                  let jsxKey = cpt+layer; 
                  const jsxData = (
                      <Box key={cpt+layer} margin={4}>
                          <Text fontSize="sm" color="3e81c3" textAlign="left" marginLeft={2}><strong>CPT Code:</strong> 
                          {cptData['cpt_code']} {cptData['units'] && "units:"+ cptData['units']}
                          
                          </Text>
                          <Text fontSize="sm" color="3e81c3" textAlign="left" marginLeft={2}><strong>Description:</strong> {desc}</Text>
                          <Text fontSize="sm" color="3e81c3" textAlign="left" marginLeft={2}><strong>Reason:</strong> {reason}</Text>
                      </Box>
                  );
          
                  // Add the formatted data to the array
                  formattedCptData.push(jsxData);
              }
          }
          
            setCpt1('');
            setCpt1desc(formattedCptData);            
            setResultsReceived(true);
            setResultsValue(JSON.stringify(data.original));
            setCodingInProgress(false);
    
    
            console.log("full ionm response: ", response);
    























          } catch (error) {
            console.error(error);
          }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setPdfUrl(null);
    
        const response = await callApi('/ionm_cpt_cms1500', accessToken, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                cpt_1: cpt1,
            }),
        });
    
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        // Store the URL in a state variable
        setPdfUrl(url);
    };


    return (
        <Box textAlign="center" fontSize="xl">
        <Grid
        templateAreas={`"header header"    
                        "main results"
                        "form form"
                        "footer footer"`}
        gridTemplateRows={'60px auto 1fr 60px'}
        gridTemplateColumns={'1fr 1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontWeight='bold'
        >
            
            
                <HeaderMenus />
            

            <GridItem bg='gray.50' padding={4} area={'main'} verticalAlign={"bottom"}>

            <Flex justifyContent="space-between">
                      
                        <Box align="left">
                            <CaseServiceList  handleCaseButtonClick={handleCaseButtonClick} />
                            <input
                                type="file"
                                id="fileInput"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />


                        </Box>
                        <br/>


                       </Flex>
                       
                       <Divider />
                    <br/>

                        <form ml={1} mr={1}>

                        <FormLabel>
                            <Text fontSize={"sm"} width="350px">{results && "PID(Primary): " + results.external_patient_id}</Text>
                            <Text fontSize={"sm"} width="350px">{results && "Patient Name: " + results.patient_first_name + " " + results.patient_last_name}</Text>
                            <Text fontSize={"sm"} width="350px">{results && "PID(Cadwell): " + results.external_patient_id_secondary}</Text>
                            <Text fontSize={"sm"} width="350px">{results && "Procedure Date: " + results.procedure_date}</Text>
                            <Text fontSize={"sm"} width="350px">{results && "Initial Process Date: " + results.create_date}</Text>
                            <Text fontSize={"sm"} width="350px">{results && "Recent Process Data: " + results.updated_at}</Text>
                            <Text fontSize={"sm"} width="350px">{results && "Case Name: " + results.case_name}</Text>
                            <Text fontSize={"sm"} width="350px">{results && "Case ID: " + results.case_id}</Text>
                            {caseFiles && caseFiles.length > 0 && (
                              <>
                                {caseFiles.map((file, index) => (
                                  <Text key={index} fontSize={"sm"} width="350px">
                                    File: <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleFileClick(results.external_patient_id, file);
                                      }}
                                      style={{ color: 'blue' }}  // Inline CSS to make the link blue
                                      >
                                      {file}
                                    </a>
                                  </Text>
                                ))}
                              </>
                            )}


                            
                            
                        </FormLabel>
                      
                     

                    {cpt1desc && (
                    <Table key="modalities" variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Parent Modality</Th>
                                <Th>Child Modality</Th>
                                <Th>Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody fontSize={"12px"} fontWeight={"normal"}>
                          {allModalities
                            .filter(([parentModality, childModality]) => {
                              const status = statusMapping[`${parentModality}:${childModality}`] || "";
                              return status === 'yes';
                            })
                            .map(([parentModality, childModality], index) => {
                              return (
                                <Tr key={index} style={{ backgroundColor: 'lightgreen' }}>
                                  <Td>
                                    <Text fontSize={"12px"} fontWeight={"normal"} lineHeight={"1"} padding={"2px"}>{parentModality}</Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={"12px"} fontWeight={"normal"} lineHeight={"1"} padding={"2px"}>{childModality}</Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={"12px"} fontWeight={"normal"} lineHeight={"1"} padding={"2px"}>yes</Text>
                                  </Td>
                                </Tr>
                              );
                            })}
                        </Tbody>

                    </Table>
                    )}
                    
                    {jsonData && (
                        <FormLabel key="data" fontSize={"sm"}>
                            Data:

                            <Textarea bgColor={"white"} fontSize={"12"} rows="200"
                                value={jsonData.replace(/\\n/g, '\n')}
                            />
                        </FormLabel>
                    )}

                   <FormLabel fontSize={"sm"}>
                        Message Data:
                        <Textarea bgColor={"white"} fontSize={"12"} value={otherData} onChange={e => setOtherData(e.target.value)} rows="20" />
                    </FormLabel>

                    </form>
                
            </GridItem>

            <GridItem bg='gray.50' padding={4} area={'results'} bgColor={"white"}>


            {chatSummaries && (
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Existing Feedback</Th>
                        </Tr>
                    </Thead>
                    <Tbody
                    fontSize={"12px"}
                    fontWeight={"normal"}
                    >
                        {Object.entries(chatSummaries)
                            .sort((a, b) => new Date(b[1].created_at) - new Date(a[1].created_at)) // Sort by created_at
                            .map(([chatId, summary], index) => (
                                <Tr 
                                    key={index} 
                                >
                                    <Td>
                                    <Text fontSize={"12px"} fontWeight={"normal"}>{summary.email}</Text>
                                    <Text fontSize={"12px"} fontWeight={"normal"}>{summary.created_at}</Text>
                                    <br/>
                                    </Td>
                                </Tr>
                            ))}
                    </Tbody>

                </Table>
            )}



            {results && resultsValue && 
            <Box minHeight={"250px"} maxHeight={"500px"}>
            <Text fontSize={"18px"}>Coding CoPilot</Text>
            <Chat 
              chatOnly={true} 
              contextType ={'ionm'} 
              contextData={resultsValue} 
              contextPersona={'nma'}
              contextCaseID={caseId}
              >
              </Chat> 
              </Box>
              }
<br/>


            <Tabs>
  <TabList>
    <Tab>CPT</Tab>
    <Tab>Coverage (Beta)</Tab>
  </TabList>
  <TabPanels>
    <TabPanel>

            {cptData && (
                <div>
                                    Results

                    <Text align="left" ml={4} fontSize="md" fontWeight="normal">{cptData}</Text>
                </div>
            )}
            {codingInProgress && (
                <div>
                    <Text align="left" ml={4} fontSize="md" fontWeight="normal">Coding in progress...</Text>
                    <Spinner size="xs" marginLeft="8px" />
                </div>
            )}

            {resultsReceived && (
            <Box textAlign="left" ml={4} fontSize="sm" fontWeight="normal" >
                <form onSubmit={handleFormSubmit}>
                <Flex>
                    <Box marginRight={4}>
                    </Box>
                    <br />
                    <Box>
                    </Box>
                </Flex>
                {cpt1desc && (
                <Flex align="center" justify="space-between">
                    <Flex align="left">
                    <Text align="left" ml={4} fontSize="sm" fontWeight="normal" bgColor={"white"}>{cpt1desc}</Text>
                    </Flex>
                </Flex>
                )}
                
                {/*
                <Button colorScheme="blue" type="submit">
                    Submit
                </Button>
                */}
                <br />
                </form>
                </Box>
            )}

    </TabPanel>
    {1 && (
    <TabPanel>


      <Box fontSize="sm" fontWeight="normal" textAlign="left">
        <Tabs>
          <TabList>
            <Tab>CMS</Tab>
            <Tab>Cigna</Tab>
            <Tab>Aetna</Tab>
          </TabList>

          <TabPanels>
    <TabPanel>

    {caseId  && ( //show button that triggers handleMNClick
              <>
                <Button
                  onClick={() => handleMNClick(caseId, 'cms')}
                  variant="link"
                  size="sm"
                  colorScheme="blue"
                >
                  Refresh
                </Button>
              </>              
              )
          }
      {relevancyErrors['cms'] && (
        <Box textAlign="left" ml={4} fontSize="md" fontWeight="normal">
          Could not generate coverage analysis results: {relevancyErrors['cms']}
        </Box>
      )}
      {relevancyResults['cms'] && 
        <RelevancyResults 
        relevancyResults={relevancyResults['cms']}
        caseId={caseId}
        handleCaseClick={handleMNClick}
        selectedPayer='cigna'
        setSelectedPayer={setSelectedPayer}        
        />
      }
      {gettingRelevancyResults['cms'] && (
        <Box textAlign="left" ml={4} fontSize="md" fontWeight="normal">
          Getting coverage analysis results, 30-90 seconds...
          <Spinner size="xs" marginLeft="8px" />
        </Box>
      )}
    </TabPanel>
    <TabPanel>
    {caseId  && ( //show button that triggers handleMNClick
              <>
                <Button
                onClick={() => handleMNClick(caseId, 'cigna')}
                variant="link"
                size="sm"
                colorScheme="blue"
              >
                  Refresh
              </Button>
              </>              
              )
          }
      {relevancyErrors['cigna'] && (
        <Box textAlign="left" ml={4} fontSize="md" fontWeight="normal">
          Could not generate coverage analysis results: {relevancyErrors['cigna']}
        </Box>
      )}
      {relevancyResults['cigna'] && 
        <RelevancyResults relevancyResults={relevancyResults['cigna']} />
      }
      {gettingRelevancyResults['cigna'] && (
        <Box textAlign="left" ml={4} fontSize="md" fontWeight="normal">
          Getting coverage analysis results, 30-90 seconds...
          <Spinner size="xs" marginLeft="8px" />
        </Box>
      )}
    </TabPanel>
    <TabPanel>
    {caseId  && ( //show button that triggers handleMNClick
              <>
                <Button
                onClick={() => handleMNClick(caseId, 'aetna')}
                variant="link"
                size="sm"
                colorScheme="blue"
              >
                  Refresh
              </Button>
              </>              
              )
          }
      {relevancyErrors['aetna'] && (
        <Box textAlign="left" ml={4} fontSize="md" fontWeight="normal">
          Could not generate coverage analysis results: {relevancyErrors['aetna']}
        </Box>
      )}
      {relevancyResults['aetna'] && 
        <RelevancyResults relevancyResults={relevancyResults['aetna']} />
      }
      {gettingRelevancyResults['aetna'] && (
        <Box textAlign="left" ml={4} fontSize="md" fontWeight="normal">
          Getting coverage analysis results, 30-90 seconds...
          <Spinner size="xs" marginLeft="8px" />
        </Box>
      )}
    </TabPanel>
  </TabPanels>        </Tabs>
          </Box>





            {cptmData && (
                <div>
                    <Text align="left" ml={4} fontSize="md" fontWeight="normal">{cptmData}</Text>
                </div>
            )}

            </TabPanel>
    )}
    <TabPanel>
        </TabPanel>
        </TabPanels>
        </Tabs>

            

            </GridItem>
            <GridItem gridArea="form">
            {pdfUrl ? (
                <div>
                            <Text>CMS 1500 form:</Text>
                            <iframe src={pdfUrl} width="100%" height="800px" />
                            </div>       
                    ) : (
                        <div>
                            <Text></Text>
                        </div>
                            )}
            </GridItem>


            <GridItem
              bg='white'
              borderTop='1px'
              borderColor='brand.200'
              paddingY={4}
              paddingX={6}
              area={'footer'}
            >
              <Flex justifyContent="center" alignItems="center">
                <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
                <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
                  &copy; {new Date().getFullYear()}
                </Text>
              </Flex>
            </GridItem>
          </Grid>
          <ChatToggle
            contextType={'revedy'}
            contextData={'revedy_ionm_coding'}
            contextPersona={'revedy_ionm_coding'}
            firstMessage={"Hi, what can I do on this page?"}
            useOptions={false}
            maintainContext={true}
            contextMode={'popup'}
        />

        </Box>

      );
    };        


export default CodeIOM;